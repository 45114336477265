import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, alpha, maxLength, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'deliveryRegions.edit',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        status: false,
        country_id: null,
        translations: [],
      },
      isDataLoading: true,
      currentTranslations: 0,
      validationErrors: {},
    }
  },
  components: {},
  validations: {
    payload: {
      country_id: {
        required
      },
      translations: {
        $each: {
          title: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(20)
          }
        }
      }
    }
  },
  created() {
    let promiseCollection = [];

    if (this.countries.length === 0) {
      promiseCollection.push(this.fetchCountries());
    }

    let deliveryRegionId = parseInt(this.$route.params.deliveryRegionId);
    if (!this.deliveryRegion || this.deliveryRegion.id !== deliveryRegionId) {
      promiseCollection.push(this.fetchDeliveryRegion(deliveryRegionId));
    }

    Promise.all(promiseCollection).finally(()=>{
      this._applyData();
      this.isDataLoading = false;
    })
  },
  mounted() {},
  computed: {
    ...mapGetters({
      countries: 'countries/countries',
      deliveryRegion: 'deliveryRegions/deliveryRegion',
      isCountriesLoading: 'countries/isCountriesLoading',
      isDeliveryRegionUpdating: 'deliveryRegions/isDeliveryRegionUpdating',
    }),
    ...mapGetters([
      'languages',
    ]),
    countryErrors() {
      let error = [];
      if (!this.$v.payload.country_id.$dirty) {
        return error;
      }
      if (!this.$v.payload.country_id.required) {
        error.push(this.$t('validationRequired'));
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      fetchCountries: 'countries/COUNTRIES_REQUEST_LIST_GET',
      fetchDeliveryRegion: 'deliveryRegions/DELIVERY_REGIONS_REQUEST_DATA_GET',
      updateDeliveryRegions: 'deliveryRegions/DELIVERY_REGIONS_REQUEST_UPDATE',
    }),
    ...mapMutations({}),
    _applyData() {
      this.payload.country_id = this.deliveryRegion.country_id;
      this.payload.status = this.deliveryRegion.status;
      this.payload.translations = this.languages.map(langItem => {
        let lang = this.deliveryRegion.translations.data.filter(translationItem => {
          return translationItem.locale === langItem.translationKey;
        })[0];

        let translationItem = {
          locale: langItem.translationKey,
          title: ''
        }

        if (lang) {
          translationItem = Object.assign(translationItem, {
            title: lang.title,
            description: lang.description,
          })
        }

        return translationItem
      });
    },
    translationsTitleErrors(index) {
      let error = [];
      if (!this.$v.payload.translations.$each[index].title.$dirty) {
        return error;
      }
      if (!this.$v.payload.translations.$each[index].title.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.translations.$each[index].title.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (!this.$v.payload.translations.$each[index].title.maxLength) {
        error.push(this.$t('validationMax',{count: 20}));
      }
      return error;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let payload = {
          country_id:  this.payload.country_id,
          status:  this.payload.status
        }
        this.payload.translations.forEach(item => {
          payload[item.locale] = {
            title: item.title
          }
        });
        this.updateDeliveryRegions({
          deliveryRegionId: this.deliveryRegion.id,
          payload: payload
        }).then(() => {
          this.$toasted.success(this.$t('dataUpdated'));
          this.$router.push({name: 'deliveryRegions.list'}).catch(() => {console.log();});
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    }
  }
}
